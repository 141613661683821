import React, { useContext, useEffect } from "react";
import Header from "../pages/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

export default function Layout() {
  const { selectedHighlight, isOpenLink } = useContext(UserContext);
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();
  // const location = useLocation()
  useEffect(() => {
    if (selectedHighlight) {
      if (isOpenLink) {
        navigate(`${isOpenLink}/${selectedHighlight}`, { replace: true });
      } else {
        navigate(`${selectedHighlight}`, { replace: true });
      }
    }
  }, [accessToken, isOpenLink]);

  // Remove leading slash if it exists
  // const pathWithoutLeadingSlash = location.pathname.replace(/^\/+/, '');
  // console.log("loca", pathWithoutLeadingSlash);
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
}
